export const customTheme = {
  token: {
    fontFamily: "Pretendard, Avenir, Helvetica, Arial, sans-serif",
    fontSize: 16,
    fontSizeSM: 15,
    colorPrimaryBg: "#353c48",
  },
};

export const theme = {};
