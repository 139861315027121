import { secureFetch } from "./fetchClient";

export const addCompanyRequest = ({
  username,
  password,
  companyName,
  feeRate,
  onePassbook,
}) => {
  return secureFetch.post("/v1/admin/company", {
    username,
    password,
    companyName,
    feeRate,
    onePassbook,
  });
};

export const addBankData = ({
  txTime,
  bank,
  txType,
  name,
  amount,
  fee,
  balance,
  totalAmount,
  companyName,
}) => {
  return secureFetch.post("/v1/admin/add-data", {
    txTime,
    bank,
    txType,
    name,
    amount,
    fee,
    balance,
    totalAmount,
    companyName,
  });
};

export const deleteBankData = (id) => {
  return secureFetch.delete("/v1/admin/delete-data", { params: { id } });
};

export const modifyBankData = (id) => {
  return secureFetch.delete("/v1/admin/delete-data", { params: { id } });
};

export const getAccounts = () => {
  return secureFetch.get("/v1/admin/user");
};

export const deleteAccount = ({ username }) => {
  return secureFetch.delete(`/v1/admin/user?username=${username}`);
};

export const resetPassword = ({ id, password }) => {
  return secureFetch.patch(`/v1/admin/user?username=${id}`, { password });
};
