import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import dayjs from "dayjs";
import ButtonCellRenderer from "./ButtonCellRenderer";
import { Modal } from "antd";
import { deleteBankData } from "../api/adminAPI";
import ListedData from "./ListedData";
import Input from "antd/es/input/Input";
// import ModifyDataModal from "./ModifyDataModal";

const DepositGrid = ({ rowData, fetchData }) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(null);

  const gridRef = useRef();

  const containerStyle = useMemo(() => ({ width: "100%", height: "60vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const rowClassRules = useMemo(
    () => ({
      "row-withdraw": (params) =>
        params.api.getValue("txType", params.node) === "WITHDRAW",
      "row-morthan-1000": (params) => {
        const isMoreThan1000 =
          params.api.getValue("amount", params.node) >= 10000000;
        const isWithdraw =
          params.api.getValue("txType", params.node) === "WITHDRAW";
        return !isWithdraw && isMoreThan1000;
      },
    }),
    []
  );

  const columnDefs = [
    {
      field: "txTime",
      headerName: "입금시간",
      minWidth: 200,
      valueFormatter: (params) =>
        dayjs(new Date(params.value)).format("YYYY-MM-DD HH:mm"),
    },
    {
      field: "bank",
      headerName: "은행",
      minWidth: 50,
    },
    {
      field: "txType",
      headerName: "이체유형",
      valueFormatter: (params) =>
        params.value === "WITHDRAW" ? "출금" : "입금",
    },
    {
      field: "name",
      headerName: "입/출금자명",
      getQuickFilterText: (params) => params.value,
    },
    {
      field: "amount",
      headerName: "입/출금금액",
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "fee",
      headerName: "수수료",
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "balance",
      headerName: "잔금",
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "totalAmount",
      headerName: "잔액",
      minWidth: 200,
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "delete",
      headerName: "삭제",
      cellRenderer: ButtonCellRenderer,
      cellRendererParams: {
        openConfirmModal: (data) => {
          setItem(data);
          setOpen(true);
          setDeleteLoading(false);
          setIsDeleteSuccess(null);
        },
        title: "삭제",
      },
      maxWidth: 100,
    },
    // {
    //   field: "modify",
    //   headerName: "수정",
    //   cellRenderer: ButtonCellRenderer,
    //   cellRendererParams: {
    //     openConfirmModal: (data) => {
    //       setItem(data);
    //       setOpenModifyModal(true);
    //     },
    //     title: "수정",
    //   },
    //   maxWidth: 100,
    // },
  ];
  const onFilterTextBoxChanged = () => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  };

  const handleOk = () => {
    setDeleteLoading(true);
    (async () => {
      try {
        const res = await deleteBankData(item.id);
        setDeleteLoading(false);
        if (res.data.success) {
          setIsDeleteSuccess(true);
          setOpen(false);
          fetchData();
        } else setIsDeleteSuccess(false);
      } catch (e) {
        setDeleteLoading(false);
        setIsDeleteSuccess(false);
      }
    })();
  };

  const handlePaginationChanged = (e) => {
    // 새 데이터 들어왔을 때 scrollToTopX
    // 페이지 이동 시에만 scroll To Top
    if (e.newPage) {
      e.api.ensureIndexVisible(0, "top");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={containerStyle}>
      <Modal
        title="데이터 삭제"
        open={open}
        onOk={handleOk}
        onCancel={() => {
          setOpen(false);
          setItem(null);
          setDeleteLoading(false);
          setIsDeleteSuccess(null);
        }}
      >
        <ListedData data={item} />
        <p>정말 삭제하시겠습니까?</p>
        {isDeleteLoading && <p>삭제 중...</p>}
        {!isDeleteLoading && isDeleteSuccess && (
          <p style={{ color: "green" }}>삭제 성공</p>
        )}
        {!isDeleteLoading && isDeleteSuccess === false && (
          <p style={{ color: "red" }}>삭제 실패</p>
        )}
      </Modal>

      <div style={gridStyle} className="ag-theme-alpine-dark">
        <Input
          type="text"
          id="filter-text-box"
          placeholder="이름 검색"
          onInput={onFilterTextBoxChanged}
        />
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowClassRules={rowClassRules}
          suppressScrollOnNewData={true}
          onPaginationChanged={handlePaginationChanged}
          pivotPanelShow={"always"}
          pagination={true}
        />
      </div>
    </div>
  );
};

export default React.memo(DepositGrid);
