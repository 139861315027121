import "antd/dist/reset.css";
import { ConfigProvider, theme } from "antd";
import { customTheme } from "./style/theme";
import "./style/App.css";

import DefaultRouter from "./DefaultRouter";
import { QueryClient, QueryClientProvider } from "react-query";

const { darkAlgorithm } = theme;
export const queryClient = new QueryClient();

function App() {
  return (
    <ConfigProvider
      theme={{
        ...customTheme,
        algorithm: darkAlgorithm,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <DefaultRouter />
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
