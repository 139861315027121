import React, { useEffect, useState } from "react";
import {
  dashboardRangeStatisticsRequest,
  dashboardRequest,
} from "../api/transactionAPI";
import { useFilterState } from "../context/FilterContext";
import { useSSEState } from "../context/SSEContext";
import DashboardCard from "./DashboardCard";
import { DatePicker } from "antd";
import useDashboardRange from "./useDashboardRange";
import { useQuery } from "react-query";

const { RangePicker } = DatePicker;

const yTitleMapper = {
  ytotalDeposit: "전날 입금 총액",
  ytotalWithdraw: "전날 출금 총액",
  ytotalFee: "전날 수수료 총액",
  ytotalBalance: "전날 잔금 총액",
};

const tTitleMapper = {
  totalDeposit: "금일 입금 총액",
  totalWithdraw: "금일 출금 총액",
  totalFee: "금일 수수료 총액",
  totalBalance: "금일 잔금 총액",
};

const yElementKeys = Object.keys(yTitleMapper);
const tElementKeys = Object.keys(tTitleMapper);

function Dashboard() {
  const { curCompany } = useFilterState();
  const [dashboardInfo, setDashboardInfo] = useState({
    ytotalDeposit: 0,
    ytotalWithdraw: 0,
    ytotalFee: 0,
    ytotalBalance: 0,
    totalDeposit: 0,
    totalWithdraw: 0,
    totalFee: 0,
    totalBalance: 0,
  });
  const {
    dashboardRange,
    handleChangeDashboardRange,
    initializeRange,
    getFormattedDashboardRange,
  } = useDashboardRange();
  const isDefaultRange = dashboardRange[0] == null && dashboardRange[1] == null;
  //dashboard의 range가 바뀌면 자동으로 업데이트 될 것!
  const {} = useQuery(
    ["dashboard", dashboardRange[0], dashboardRange[1], curCompany],
    () => {
      if (isDefaultRange) {
        return dashboardRequest(curCompany.companyName);
      }
      const [startDt, endDt] = getFormattedDashboardRange();
      return dashboardRangeStatisticsRequest({
        companyName: curCompany.companyName,
        startDt,
        endDt,
      });
    },
    {
      onSuccess: (res) => {
        const { data } = res;
        if (data.hasOwnProperty("totalDeposit")) {
          setDashboardInfo(data);
        } else setDashboardInfo((prev) => Object.assign({}, prev, data));
      },
      refetchOnWindowFocus: false,
    }
  );

  const { SSEClient } = useSSEState();

  // 1. 위에거 default일때만 sse값에 따라 업데이트 되도록
  // 2. company선택이 바뀌면 range는 default로 바뀌어야 함
  useEffect(() => {
    //defaultRange로 바꾸어줌!
    initializeRange();
    // queryClient.invalidateQueries({ queryKey: ["dashboard", null, null] });
    // SSE로 실시간 업데이트, defaultRange일 때만 업데이트 되도록.
    const getRealtimeDashboardData = (event) => {
      const data = JSON.parse(event.data);

      if (isDefaultRange && data.companyName === curCompany.companyName)
        setDashboardInfo((prev) => Object.assign({}, prev, data));
    };

    SSEClient?.addEventListener("dashboard", getRealtimeDashboardData);

    return () => {
      if (SSEClient)
        SSEClient.removeEventListener("dashboard", getRealtimeDashboardData);
    };
  }, [curCompany]);

  return (
    <section>
      <div className="card-container">
        <div style={{ textAlign: "left", marginBottom: "1rem" }}>
          <RangePicker
            placeholder={["시작날짜", "끝날짜"]}
            value={dashboardRange}
            format="YYYY MM/DD"
            onChange={handleChangeDashboardRange}
          />
        </div>
        <div className="card-wrapper">
          {yElementKeys.map((key) => (
            <DashboardCard
              title={yTitleMapper[key]}
              key={key}
              value={dashboardInfo[key]}
            />
          ))}
        </div>
        <div className="card-wrapper">
          {tElementKeys.map((key) => (
            <DashboardCard
              title={tTitleMapper[key]}
              key={key}
              value={dashboardInfo[key]}
              today={true}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Dashboard;

//요청시간, 입금자명, 입금금액, 회사
