import { commonFetch, secureFetch } from "./fetchClient";

export const loginRequest = (data) => {
  return commonFetch.post("/v1/user/login", {
    username: data.id,
    password: data.password,
  });
};

export const revalidateCompanylist = () => {
  return secureFetch.get("/v1/user");
};
