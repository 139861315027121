import { useState } from "react";
import { rangeFormatter } from "../utils";

// const yesterdayDate = getYesterdayDate();
const useDashboardRange = () => {
  const [dashboardRange, setDashboardRange] = useState([null, null]);

  //initilizing range
  const initializeRange = () => {
    setDashboardRange([null, null]);
  };

  //dashboardRange update
  const handleChangeDashboardRange = (val) => {
    if (Array.isArray(val)) {
      setDashboardRange(val);
    } else {
      initializeRange();
    }
  };

  //포매팅된 값
  const getFormattedDashboardRange = () => {
    return rangeFormatter(...dashboardRange);
  };

  return {
    dashboardRange,
    handleChangeDashboardRange,
    getFormattedDashboardRange,
    initializeRange,
  };
};

export default useDashboardRange;
