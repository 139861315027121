import { Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const headerStyle = {
  textAlign: "left",
  color: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  gap: "1rem",
  background: "#4c5667",
};
const contentStyle = {
  textAlign: "center",
  padding: "2rem",
  minHeight: "calc( 100vh - 64px )",
  background: "#475060",
};

function AdminLayout() {
  return (
    <Layout>
      <Header style={headerStyle}>
        <NavLink
          to={"/main"}
          className={({ isActive }) => (isActive ? "active" : "normal")}
        >
          메인화면으로 가기
        </NavLink>
        <NavLink
          to={"add-account"}
          className={({ isActive }) => (isActive ? "active" : "normal")}
        >
          계정 추가
        </NavLink>
        <NavLink to={"manage-account"} className="normal">
          비밀번호 재설정/계정 삭제
        </NavLink>
      </Header>
      <Content style={contentStyle}>
        <Outlet />
      </Content>
    </Layout>
  );
}

export default AdminLayout;
