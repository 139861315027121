import { secureFetch } from "./fetchClient";

export const transactionRequest = (data) => {
  return secureFetch.get("/v1/txs", { params: data });
};

export const pendingTransactionRequest = () => {
  return secureFetch.get("/v1/txs/pending");
};

export const dashboardRequest = (data) => {
  return secureFetch.get("/v1/dashboard", { params: { companyName: data } });
};

export const dashboardRangeStatisticsRequest = (data) =>
  secureFetch.get("/v1/dashboard", { params: data });

//sse
/**
 *
 * pendingTx => 추가
 * removePendingTx => 삭제 (id, bank)
 */
