import { Button, Modal, Space, Table, Tooltip } from "antd";
import React, { useState } from "react";
import PasswordReset from "./PasswordReset";
import withAuth from "../../hoc/withAuth";
import { useMutation, useQuery } from "react-query";
import { deleteAccount, getAccounts } from "../../api/adminAPI";
import { queryClient } from "../../App";

function ManageAccount() {
  const [modal, setModal] = useState({
    open: false,
    type: "reset",
    username: "",
  });

  const { isLoading, data } = useQuery(["accounts"], getAccounts, {
    refetchOnWindowFocus: false,
  });
  const {
    mutate,
    isSuccess,
    isError,
    isLoading: deleteLoading,
    reset,
  } = useMutation({
    mutationFn: deleteAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["accounts"] });
      setTimeout(() => {
        reset();
        resetModal();
      }, 500);
    },
    refetchOnWindowFocus: false,
  });

  const dataSource = data?.data.users.map((user) => ({
    key: user.username,
    username: user.username,
    company: user.companyList.map((company) => company.companyName).join(", "),
  }));

  const resetModal = () => {
    setModal({ open: false, type: "reset", username: "" });
  };

  const openPwdResetModal = (id) => {
    setModal({ open: true, type: "reset", username: id });
  };

  const openDeleteAccountModal = (id) => {
    setModal({ open: true, type: "delete", username: id });
  };

  const handleOkDeleteAccountBtn = () => {
    mutate({ username: modal.username });
  };

  const ModalContent =
    modal.type === "reset" ? (
      <Modal
        title={"비밀번호 재설정"}
        open={modal.open}
        onCancel={resetModal}
        footer={[]}
      >
        <PasswordReset username={modal.username} resetModal={resetModal} />
      </Modal>
    ) : (
      <Modal
        title="계정 삭제"
        open={modal.open}
        onOk={handleOkDeleteAccountBtn}
        onCancel={resetModal}
      >
        {modal.username}계정을 삭제하시겠습니까?
        {deleteLoading && <p>계정 삭제 중...</p>}
        {!deleteLoading && isSuccess && (
          <p style={{ color: "green" }}>계정 삭제를 성공했습니다.</p>
        )}
        {!deleteLoading && isError && (
          <p style={{ color: "red" }}>계정 삭제를 실패했습니다.</p>
        )}
      </Modal>
    );

  const columns = [
    { title: "username", dataIndex: "username", key: "username" },
    {
      title: "company",
      dataIndex: "company",
      key: "company",
      ellipsis: { showTitle: false },
      render: (company) => (
        <Tooltip placement="topLeft" title={company}>
          {company}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="small" key={`button-${record.key}`}>
            <Button
              size="small"
              onClick={() => openPwdResetModal(record.username)}
            >
              비밀번호 재설정
            </Button>
            <Button
              size="small"
              onClick={() => openDeleteAccountModal(record.username)}
            >
              계정 삭제
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      {ModalContent}
      <h2 style={{ fontWeight: "bold" }}>계정 관리</h2>
      <Table columns={columns} dataSource={dataSource} loading={isLoading} />
    </div>
  );
}

export default withAuth(ManageAccount);
