const isDev = process.env.NODE_ENV === "development";

const COMPANY = process.env.REACT_APP_COMPANY || "default";

const paths = {
  bankone: "./bankone",
  banktwo: "./banktwo",
  bankthree: "./bankthree",
  bankfour: "./bankfour",
  banktest: "./banktest",
  default: "./default",
};

const pathMapper = (company) => paths[company] || paths.default;

const COMPANY_SETTING_PATH = isDev ? paths.banktest : pathMapper(COMPANY);

const settings = require(`${COMPANY_SETTING_PATH}/setting.json`);
const banks = require(`./banks.json`);

module.exports.settings = settings;
module.exports.banks = banks;
