import { Button, Card, Form, Input } from "antd";
import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { addCompanyRequest } from "../../api/adminAPI";
import { settings } from "../../settings/config";
import Checkbox from "antd/es/checkbox/Checkbox";

const useSinglePassbook = settings?.useSinglePassbook || false;

const SUCCESS = "SUCCESS";
const FAIL = "FAIL";
const NONE = null;

function Admin({ isAdmin }) {
  const [createStatus, setCreateStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const pendingData = () => {
    setIsLoading(true);
    setCreateStatus(NONE);
  };
  const onFinish = (value) => {
    pendingData();
    (async () => {
      try {
        const val = useSinglePassbook
          ? value
          : Object.assign(value, { onePassbook: false });
        const res = await addCompanyRequest(val);
        setIsLoading(false);
        if (res.data.success === false) setCreateStatus(FAIL);
        else setCreateStatus(SUCCESS);
      } catch (e) {
        setIsLoading(false);
        setCreateStatus(FAIL);
      }
    })();
  };

  if (!isAdmin) {
    window.alert("관리자 권한이 필요한 페이지입니다.");
    return <Navigate to={"/login"} />;
  }
  // username, password, companyName, feeRate;

  return (
    <Card
      title="계정 추가"
      style={{
        width: "80vw",
        margin: "0 auto",
        minWidth: "400px",
        maxWidth: "800px",
      }}
      headStyle={{ backgroundColor: "#3b404b", color: "white" }}
    >
      <Form
        name="add-user"
        initialValues={{ remember: false }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "ID를 입력해주세요" }]}
        >
          <Input size="large" addonBefore="ID" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "비밀번호를 입력해주세요" }]}
        >
          <Input.Password type="password" size="large" addonBefore="비밀번호" />
        </Form.Item>
        <Form.Item
          name="feeRate"
          rules={[{ required: true, message: "수수료율을 입력해주세요" }]}
        >
          <Input type="number" size="large" addonBefore="수수료율" max={100} />
        </Form.Item>
        <Form.Item
          name="companyName"
          rules={[{ required: true, message: "회사이름을 입력해주세요" }]}
        >
          <Input size="large" addonBefore="회사이름" />
        </Form.Item>
        {useSinglePassbook && (
          <Form.Item name="onePassbook" valuePropName="checked">
            <Checkbox>통장 한 개로 운영시 체크 해주세요 </Checkbox>
          </Form.Item>
        )}

        <Form.Item labelAlign="right">
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", backgroundColor: "black" }}
          >
            계정 생성
          </Button>
        </Form.Item>
      </Form>
      {isLoading && <p>계정 생성 중..</p>}
      {createStatus === SUCCESS && (
        <p style={{ color: "green" }}>계정 생성에 성공했습니다</p>
      )}
      {createStatus === FAIL && (
        <p style={{ color: "red" }}>계정 생성에 실패했습니다</p>
      )}
      <Link to={-1}>이전 페이지로</Link>
    </Card>
  );
}

export default Admin;
