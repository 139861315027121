import { Button, Form, Input } from "antd";
import React, { useRef } from "react";
import { useMutation } from "react-query";
import { resetPassword } from "../../api/adminAPI";

function PasswordReset({ username, resetModal }) {
  // 버튼 클릭하면 비밀번호 재설정 요청 -> 성공 시 성공 alert ..
  // console.log(username, resetModal);
  const formRef = useRef(null);
  const resetForm = () => {
    formRef.current.setFieldsValue({ password: undefined });
  };
  const { mutate, isSuccess, isError, isLoading, reset } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      setTimeout(() => {
        reset();
        resetForm();
        resetModal();
      }, 2000);
    },
  });

  const handleOnFinish = (value) => {
    mutate({ id: username, password: value.password });
    //console.log(username, value.password);
  };

  return (
    <Form name="reset-password" onFinish={handleOnFinish} ref={formRef}>
      <Form.Item name="password">
        <Input.Password
          type="password"
          size="large"
          addonBefore="새 비밀번호"
        />
      </Form.Item>
      <Form.Item labelAlign="right">
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%", backgroundColor: "black" }}
        >
          수정
        </Button>
        {isLoading && <p>데이터 추가 중...</p>}
        {!isLoading && isSuccess && (
          <p style={{ color: "green" }}>비밀번호 수정을 성공했습니다.</p>
        )}
        {!isLoading && isError && (
          <p style={{ color: "red" }}>비밀번호 수정을 실패했습니다.</p>
        )}
      </Form.Item>
    </Form>
  );
}

export default PasswordReset;
