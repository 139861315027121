import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import DepositGrid from "../../components/DepositGrid";
import { Button, Layout, Space, Tabs, notification } from "antd";
import { useSSEState } from "../../context/SSEContext";
import Dashboard from "../../components/Dashboard";
import Filter from "../../components/Filter";
import HeaderContent from "../../components/HeaderContent";
import dayjs from "dayjs";
import { useFilterState } from "../../context/FilterContext";
import { transactionRequest } from "../../api/transactionAPI";
import AddDataModal from "../../components/AddDataModal";
import createXml from "../../createXml";
import useSound from "use-sound";
import dingdongSound from "./alarmsound.mp3";
import moreThan200Sound from "./beepsound.mp3";
import PendingList from "../../components/PendingList";
import { ROLE_ADMIN } from "../../constant/role";
const { Header, Content } = Layout;

const headerStyle = {
  textAlign: "left",
  color: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  background: "#4c5667",
};
const contentStyle = {
  textAlign: "center",
  padding: "2rem",
  minHeight: "calc( 100vh - 64px )",
  background: "#475060",
};

const today = dayjs().format("YYYY MM/DD");

const IS_BANKTWO = process.env.REACT_APP_COMPANY === "banktwo";

function Main() {
  const [rowData, setRowData] = useState([]);
  const [play] = useSound(dingdongSound, { volume: 0.5 });
  const [play_more_than_200] = useSound(moreThan200Sound, { volume: 0.5 });
  const [playAlarm, setPlayAlarm] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const isMobile = useMemo(
    () => window.matchMedia("(max-width: 600px)").matches,
    []
  );

  const isAdmin = useMemo(
    () => JSON.parse(sessionStorage.getItem("authority"))?.includes(ROLE_ADMIN),
    []
  );

  const isToday = useRef(true);

  const { SSEClient } = useSSEState();
  const { curCompany, dateRange } = useFilterState();

  const ringAlarm = () => {
    if (playAlarm) play();
  };

  const fetchGridRowData = useCallback(async () => {
    const data = {
      companyName: curCompany.companyName,
      startDt: dateRange[0] || today + " 00:00",
      endDt: dateRange[1] || today + " 24:00",
    };
    isToday.current = !dateRange[0] && !dateRange[1] ? true : false;
    try {
      const res = await transactionRequest(data);
      if (res.data.success) setRowData(res.data.txs);
    } catch (e) {
      console.error("fetch data error");
    }
  }, [curCompany, dateRange, today]);

  const updateRowData = useCallback(
    (event) => {
      const data = JSON.parse(event.data);
      if (playAlarm) {
        if (IS_BANKTWO && isAdmin && data.totalAmount >= 2_000_000)
          play_more_than_200();
        else play();
      }
      api.open({
        message: "실시간 데이터 알림",
        description: `${data.companyName}에 입/출금 내역이 추가되었습니다`,
        duration: 5,
      });

      if (isToday.current && curCompany.companyName === data.companyName) {
        setRowData((prev) => [data, ...prev]);
      }
    },
    [curCompany, playAlarm, play, api]
  );

  useEffect(() => {
    fetchGridRowData();

    //**temp */
    SSEClient.addEventListener("tx", updateRowData);

    return () => {
      if (SSEClient) {
        SSEClient.removeEventListener("tx", updateRowData);
      }
    };
  }, [curCompany, playAlarm]);

  const items = [
    {
      label: "입/출금 현황",
      key: "입출금내역",
      children: <DepositGrid rowData={rowData} fetchData={fetchGridRowData} />,
    },
  ];
  if (isAdmin)
    items.push({
      label: "대기내역",
      key: "입출금대기",
      children: <PendingList ringAlarm={ringAlarm} />,
    });

  return (
    <Layout>
      {contextHolder}
      <Header style={headerStyle}>
        <HeaderContent isAdmin={isAdmin}>
          <div>
            <label htmlFor="alarm">알림음 울리기</label>
            <input
              name="alarm"
              type="checkbox"
              checked={playAlarm}
              onChange={() => {
                // console.log("IS TRIGGERED?", playAlarm);
                setPlayAlarm((prev) => !prev);
              }}
            />
          </div>
        </HeaderContent>
      </Header>
      <Content style={contentStyle}>
        <Space direction="vertical" style={{ width: "100%" }} size={[0, 40]}>
          <Dashboard />
          <div
            style={{
              display: isMobile ? "block" : "flex",
              justifyContent: "end",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "10px",
                }}
              >
                {isAdmin && <AddDataModal fetchData={fetchGridRowData} />}
                <Button onClick={() => createXml(rowData)}>
                  엑셀 다운로드
                </Button>
              </div>
              <Filter fetchData={fetchGridRowData} />
            </div>
          </div>
          <Tabs type="card" items={items} destroyInactiveTabPane={true} />
        </Space>
      </Content>
    </Layout>
  );
}

export default Main;
