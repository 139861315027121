import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import dayjs from "dayjs";

import Input from "antd/es/input/Input";
import { useFilterState } from "../context/FilterContext";
import { pendingTransactionRequest } from "../api/transactionAPI";
import { useSSEState } from "../context/SSEContext";
import { notification } from "antd";

const PendingList = ({ ringAlarm }) => {
  const { curCompany } = useFilterState();
  const gridRef = useRef();
  const [row, setRow] = useState([]);

  const { SSEClient } = useSSEState();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    (async () => {
      try {
        const res = await pendingTransactionRequest();
        if (res.data.success) {
          setRow(res.data.txs);
        }
      } catch (e) {}
    })();

    const handleAddPendingData = (event) => {
      const data = JSON.parse(event.data);
      ringAlarm();
      api.open({
        message: "입금 대기내역 추가 알림",
        description: `입금 대기 내역이 추가되었습니다`,
        duration: 5,
      });
      setRow((prev) => [data, ...prev]);
    };

    const handleRemovePendingData = (event) => {
      const data = JSON.parse(event.data);

      setRow((prev) => {
        const newRow = [...prev];
        const removeIdx = newRow.findIndex((element) => element.id === data.id);
        newRow.splice(removeIdx, 1);
        return newRow;
      });
    };
    SSEClient.addEventListener("pendingTx", handleAddPendingData);

    SSEClient.addEventListener("rmPendingTx", handleRemovePendingData);

    return () => {
      if (SSEClient) {
        SSEClient.removeEventListener("pendingTx", handleAddPendingData);
        SSEClient.removeEventListener(
          "removePendingTx",
          handleRemovePendingData
        );
      }
    };
  }, [curCompany]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "60vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const rowClassRules = useMemo(
    () => ({
      "row-withdraw": (params) =>
        params.api.getValue("txType", params.node) === "WITHDRAW",
      "row-morthan-1000": (params) => {
        const isMoreThan1000 =
          params.api.getValue("amount", params.node) >= 10000000;
        const isWithdraw =
          params.api.getValue("txType", params.node) === "WITHDRAW";
        return !isWithdraw && isMoreThan1000;
      },
    }),
    []
  );

  const columnDefs = [
    {
      field: "txTime",
      headerName: "입금시간",
      minWidth: 200,
      valueFormatter: (params) =>
        dayjs(new Date(params.value)).format("YYYY-MM-DD HH:mm"),
    },
    {
      field: "bank",
      headerName: "은행",
      minWidth: 50,
    },
    {
      field: "name",
      headerName: "입금자명",
      getQuickFilterText: (params) => params.value,
    },
    {
      field: "amount",
      headerName: "입금금액",
      valueFormatter: (params) => params.value.toLocaleString(),
    },
  ];
  const onFilterTextBoxChanged = () => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  };

  const handlePaginationChanged = (e) => {
    // 새 데이터 들어왔을 때 scrollToTopX
    // 페이지 이동 시에만 scroll To Top
    if (e.newPage) {
      e.api.ensureIndexVisible(0, "top");
    }
  };

  return (
    <div style={containerStyle}>
      {contextHolder}
      <div style={gridStyle} className="ag-theme-alpine-dark">
        <Input
          type="text"
          id="filter-text-box"
          placeholder="이름 검색"
          onInput={onFilterTextBoxChanged}
        />
        <AgGridReact
          ref={gridRef}
          rowData={row}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowClassRules={rowClassRules}
          suppressScrollOnNewData={true}
          onPaginationChanged={handlePaginationChanged}
          pivotPanelShow={"always"}
          pagination={true}
        />
      </div>
    </div>
  );
};

export default React.memo(PendingList);
