import React, { useState, useEffect } from "react";
import { rangeFormatter, updateCompanyList } from "../utils";
import { revalidateCompanylist } from "../api/authAPI";

export const FilterContext = React.createContext();

export const FilterContextProvider = ({ children }) => {
  const [companyList, setCompanyList] = useState(
    JSON.parse(sessionStorage.getItem("companyList")) || []
  );

  const [curCompany, setCurCompany] = useState(
    companyList.length ? companyList[0] : { feeRate: 0 }
  );

  const feeRate = companyList?.reduce((accum, cur) => {
    accum[cur.companyName] = cur.feeRate;
    return accum;
  }, {});

  const [dateRange, setDateRange] = useState([null, null]);

  //remount시 companyList Update하는 과정
  useEffect(() => {
    const revalidate = async () => {
      try {
        const refetchedCompanyList = await revalidateCompanylist();
        const companyList = refetchedCompanyList.data.user.companyList;
        updateCompanyList(companyList);
        setCompanyList(companyList);
        setCurCompany(companyList[0]);
      } catch (e) {
        updateCompanyList([]);
        setCompanyList([]);
        setCurCompany({ companyName: "", feeRate: 0 });
      }
    };
    revalidate();
  }, []);

  const updateCurCompany = (data) => {
    if (typeof data === "string")
      data = { companyName: data, feeRate: feeRate[data] };
    const realData = Object.assign({}, data);
    setCurCompany(realData);
  };

  const updateDateRange = (value) => {
    if (Array.isArray(value)) {
      const formedRange = rangeFormatter(value[0], value[1]);
      setDateRange(formedRange);
    } else setDateRange([null, null]);
  };

  const value = {
    companyList,
    curCompany,
    updateCurCompany,
    dateRange,
    updateDateRange,
  };

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export const useFilterState = () => React.useContext(FilterContext);
